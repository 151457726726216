import Header from "../../components/header/Header";
import React from 'react';
import { API_URL, axiosconfig } from '../../core';
import axios from 'axios';
import TextTruncate from 'react-text-truncate';
import './Blog.css';
import Animate from 'react-smooth';

class Blog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pubs: {},
            page: 1
        };
    }
    componentDidMount() {
        let url = API_URL + '/api/pubs?format=json'
        axios.get(url, axiosconfig)
            .then(res => {
                this.setState({ pubs: res.data });
            })
    }
    formatterDate(dt) {
        return dt ? Intl.DateTimeFormat('pt-BR').format(new Date(dt)) : '';
    }
    updatePubs(action) {
        let url = API_URL + `/api/pubs?page=${action === 'next' ? this.state.page + 1 : this.state.page - 1}&format=json`
        axios.get(url, axiosconfig)
            .then(res => {
                this.setState({ pubs: res.data, page: action === 'next' ? this.state.page + 1 : this.state.page - 1 });
            })
    }
    render() {
        return (
            <Animate to="1" from="0" attributeName="opacity">
            <>
                <Header />
                <div className="clearfix str" style={{ paddingTop: '4rem' }}>
                    <div style={{ backgroundColor: '#fb7100', textAlign: 'center' }}>
                        <h3 style={{ paddingTop: '2rem', paddingBottom: '2rem', fontWeight: 'bold', color: '#fff' }}>Blog Zancanaro</h3>
                    </div>
                    <section>
                        <div className="content" style={{ paddingTop: '1.5rem' }}>
                            <div className="container clearfix">
                                <div id="posts" className="post-grid row grid-container gutter-30" data-layout="fitRows" style={{ justifyContent: 'center' }}>
                                    {this.state.pubs.results ? this.state.pubs.results.map((item, i) =>
                                        <div className="entry col-lg-3 col-md-4 col-sm-6 col-12">
                                            <div className="grid-inner">
                                                <div className="entry-image">
                                                    <a href={`/pub/${item.slug}`} data-lightbox="image"><img src={`${API_URL}${item.image}`} alt={item.title} /></a>
                                                </div>
                                                <div className="entry-title">
                                                    <h2><a href={`/pub/${item.slug}`}>{item.title}</a></h2>
                                                </div>
                                                <div className="entry-meta">
                                                    <ul>
                                                        <li><i className="icon-calendar3"></i> {this.formatterDate(item.pub)}</li>
                                                    </ul>
                                                </div>
                                                <div className="entry-content">
                                                    <p>
                                                        <TextTruncate
                                                            line={3}
                                                            element="span"
                                                            truncateText="…"
                                                            text={item.content}
                                                            textTruncateChild={''}
                                                        /></p>
                                                    <a href={`/pub/${item.slug}`} >
                                                        <button className="btn-saiba-mais-blog">Ler mais</button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>

                                <div className="d-flex justify-content-between mt-5">
                                    {this.state.pubs.previous ? <button className="btn btn-outline-secondary" onClick={() => this.updatePubs('previous')}>&larr; Anterior</button> : <div></div>}
                                    {this.state.pubs.next ? <button className="btn btn-outline-dark" onClick={() => this.updatePubs('next')}>Próxima &rarr;</button> : <div></div>}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
            </Animate>
        );
    }
}
export default Blog;