import { CardOrcamento } from "../../components/cardorcamento/CardOrcamento";
import Contato from "../../components/contato/Contato";
import Header from "../../components/header/Header";
import './ServiceDetail.css';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { API_URL, axiosconfig } from "../../core";
import React from 'react';
import axios from 'axios';
import Animate from 'react-smooth';

class ServiceDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
          service: {}
        };
      }
      componentDidMount() {
          let url = API_URL + `/api/service?slug=${this.props.match.params.slug}&format=json`
          axios.get(url, axiosconfig)
              .then(res => {
              this.setState({ service : res.data });
          })
      }
      render(){
        return (
            <Animate to="1" from="0" attributeName="opacity">
            <>
                <Header />
                <div className="stretched mgtop">
                    <Animate to="1" from="0" attributeName="opacity">
                    <img src={`${API_URL}${this.state.service.banner}`} alt="banner" style={{ width: '100%' }} />
                    </Animate>
                </div>
                <div className="container">
                    <div className="box">
                        <Carousel autoPlay showThumbs={false} showArrows={false} showStatus={false} className="svgs">
                            {this.state.service.slide01 === '/uploads/404.png' ?
                            null 
                            :
                            <div>
                                <img alt="Serviço Zancanaro" src={`${API_URL}${this.state.service.slide01}`} />
                            </div>
                            }
                            {this.state.service.slide02 === '/uploads/404.png' ?
                            null
                            :
                            <div>
                            <img alt="Serviço Zancanaro" src={`${API_URL}${this.state.service.slide02}`} />
                            </div>
                            }
                            {this.state.service.slide03 === '/uploads/404.png' ?
                            null
                            :
                            <div>
                            <img alt="Serviço Zancanaro" src={`${API_URL}${this.state.service.slide03}`} />
                            </div>
                            }
                        </Carousel>
                        <div style={{ paddingTop: '1rem', marginBottom: '2rem' }}>
                            <b>{this.state.service.title}</b>
                        </div>
                        <p style={{whiteSpace: 'pre-wrap'}}>
                            {this.state.service.contentlong}
                        </p>
                    </div>
                   
                </div>
                <CardOrcamento />
                <Contato />
            </>
            </Animate>
        );
    }
}
export default ServiceDetail;