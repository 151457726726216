import React from 'react';
import { API_URL, axiosconfig } from '../../core';
import axios from 'axios';
import Animate from 'react-smooth';
import './Servicos.css';

class Servicos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            services: [],
            materials: []
        };
    }

    componentDidMount() {
        let url = API_URL + '/api/services?format=json'
        axios.get(url, axiosconfig)
            .then(res => {
                this.setState({ services: res.data });
            })
        let url2 = API_URL + '/api/materials?format=json'
        axios.get(url2, axiosconfig)
            .then(res => {
                this.setState({ materials: res.data });
            })
    }
    render() {
        return (
            <Animate to="1" from="0" attributeName="opacity">
                <div className="container" style={{ paddingTop: '6rem' }}>
                    <div className="heading-block center">
                        <h2 style={{ color: '#ff6000', textTransform: 'none' }}>Serviços</h2>
                        <span>Sua obra bem executada, com materiais de qualidade e profissionalismo.
                        Conheça os serviços do Grupo Zancanaro
                            </span>
                    </div>
                    <div className="row col-mb-50" >
                        {this.state.materials.map((item, i) =>
                            <div className="card text-center col-lg-3" style={{ border: 'none' }} key={i}>
                                <div className="card-header" style={{ backgroundColor: '#fff', border: 'none' }}>
                                    <img src={`${API_URL}${item.icon}`} alt="serviço" style={{ width: '80px' }} className="service" />
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title" style={{ color: '#ff6000', fontSize: '0.85rem', fontWeight: 'bold' }}>{item.title}</h5>
                                    <p className="card-text" style={{ fontSize: '0.8rem', color: '#9996966' }}>
                                        {item.content}
                                    </p>
                                </div>
                                <div className="card-footer text-muted" style={{ border: 'none', backgroundColor: '#FFF', margin: '0' }}>
                                    <a href={`/materiais/${item.slug}`} className="btn-saiba-mais">Saiba mais</a>
                                </div>
                            </div>

                        )}
                        {this.state.services.map((item, i) =>
                            <div className="card text-center col-lg-3" style={{ border: 'none' }} key={i}>
                                <div className="card-header" style={{ backgroundColor: '#fff', border: 'none' }}>
                                    <img src={`${API_URL}${item.icon}`} alt="serviço" style={{ width: '80px' }} className="service" />
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title" style={{ color: '#ff6000', fontSize: '0.85rem', fontWeight: 'bold' }}>{item.title}</h5>
                                    <p className="card-text" style={{ fontSize: '0.8rem', color: '#9996966' }}>
                                        {item.content}
                                    </p>
                                </div>
                                <div className="card-footer text-muted" style={{ border: 'none', backgroundColor: '#FFF', margin: '0' }}>
                                    <a href={`/servico/${item.slug}`} className="btn-saiba-mais">Saiba mais</a>
                                </div>
                            </div>

                        )}
                    </div>
                </div>
            </Animate>
        );
    }
}
export default Servicos;
