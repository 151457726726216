import Banner from "../../components/banner/Banner";
import { Bannerorcamento } from "../../components/bannerorcamento/Bannerorcamento";
import BlogHome from "../../components/bloghome/Bloghome";
import Contato from "../../components/contato/Contato";
import Header from "../../components/header/Header";
import Servicos from "../../components/servicos/Servicos";

export function Home() {
    return (
        <div className="App">            
                <div className="clearfix">
                    <Header />
                    <Banner />
                    <Servicos />
                    <Bannerorcamento />
                    <BlogHome />
                    <Contato />
                </div>
        </div>

    );
}