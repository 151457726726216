import React from 'react';
import { API_URL, axiosconfig } from '../../core';
import axios from 'axios';
import TextTruncate from 'react-text-truncate';
import './Bloghome.css';
import Animate from 'react-smooth';

class BlogHome extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pubs: []
		};
	}

	componentDidMount() {
		let url = API_URL + '/api/pubs?page_size=3&format=json'
		axios.get(url, axiosconfig)
			.then(res => {
				this.setState({ pubs: res.data });
			})
	}
	render() {
		return (
			<Animate to="1" from="0" attributeName="opacity">
			<section style={{ backgroundColor: '#fb7100', marginTop: '0', paddingTop: '0' }}>
				<div className="container" style={{ paddingTop: '70px' }}>
					<div className="heading-block center">
						<h2 style={{ color: 'white' }}>Blog</h2>
						<span style={{ color: 'white' }}>Fique por dentro das novidades da construção</span>
					</div>

					<div className="row col-mb-50">
						{this.state.pubs.results ? this.state.pubs.results.map((item, i) =>
							<div className="col-md-4" key={i}>
								<div className="feature-box media-box">
									<div className="fbox-media">
										<img src={`${API_URL}${item.image}`} alt="blog" />
									</div>
									<div className="fbox-content px-0">
										<h3 style={{ color: '#222121', fontWeight: 'bold' }}>{item.title}</h3>
										<span className="subtitle">
											<TextTruncate
												line={2}
												element="span"
												truncateText="…"
												text={item.subtitle}
												textTruncateChild={""}
											/>
										</span>
										<p style={{ color: 'white' }}>
											<TextTruncate
												line={4}
												element="span"
												truncateText="…"
												text={item.content}
												textTruncateChild={""}
											/>
										</p>
									</div>
								</div>
								<a href={`/pub/${item.slug}`} >
									<button className="btn-saiba-mais-blog">Ler mais</button>
								</a>						
							</div>
						) : <></>}
					</div>
				</div>
			</section>
			</Animate>
		);
	}
}
export default BlogHome;