import Contato from "../../components/contato/Contato";
import Header from "../../components/header/Header";
import React from 'react';
import { API_URL, axiosconfig, GOOGLE_SITE_KEY } from '../../core';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import Animate from 'react-smooth';

const recaptchaRef = React.createRef();
const defaultSelect = '- Selecione -';

class Orcamento extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            services : [],
			name: '',
			phone: '',
			email: '',
			city : '',
            uf : '',
            celphone: '',
            construction: defaultSelect,
            besttime: '',
            details: '',
			sent: false,
            selectedService : defaultSelect,
            selectedProduct: defaultSelect,
            checkFields: false,
            err : false
		};
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(e) {
		e.preventDefault();
        const st = this.state;
        const recaptchaValue = recaptchaRef.current.getValue();
		const data = new FormData()
        data.append('name', this.state.name);
        data.append('phone', this.state.phone);
        data.append('email', this.state.email);
        data.append('city', this.state.city);
        data.append('uf', this.state.uf);
        data.append('celphone', this.state.celphone);
        data.append('besttime', this.state.besttime);
        data.append('construction', this.state.construction);
        data.append('service', this.state.selectedService);
        data.append('product', this.state.selectedProduct);
        data.append('details', this.state.details);
		data.append('grecaptcha', recaptchaValue);
        if (st.construction === defaultSelect || st.selectedService === defaultSelect){
            this.setState({checkFields: true})
        }else{            
            axios.post(`${API_URL}/api/invoice`, data, axiosconfig)
                .then(res => {
                    if (res.status === 200) {
                        this.setState({ sent: true, err: false })
                    }
                }).catch(err =>
                    this.setState({err: true})
                )
        }
		return false;
	}
    componentDidMount() {
        let url = API_URL + '/api/services?format=json'
        axios.get(url, axiosconfig)
            .then(res => {
            this.setState({ services : res.data });
            console.log(res.data);
            })
    }
    setService(serv){
        if (serv === 'Britador'){
            this.setState({selectedService: serv, selectedProduct: 'Não especificado'});
        }else{
            this.setState({selectedService: serv, selectedProduct: '- Selecione -'});
        }        
    }

    getProducts(){
        let prods = [];
        this.state.services.map((item) => {
            if (item.title === this.state.selectedService){
                item.products.map((item2) => {
                    prods.push(item2.title);
                    return false;
                })
            }
            return false;
        });
        return prods;
    }

    checkBrit(){
        return this.state.selectedService === 'Britador' ? true : false;
    }

	render() {
        return (
            <Animate to="1" from="0" attributeName="opacity">
            <>
                <Header />
                <div className="clearfix " style={{ paddingTop: '3rem' }}>
                    <section id="page-title" className="page-title-center">
                        <div className="container clearfix">
                            <h2>Orçamento</h2>
                            <span>Tem um projeto de empreendimento?</span>
                            <span>Conte com a gente para realizá-lo com eficiência e o melhor custo.</span>
                        </div>
                    </section>
                </div>
                <section className="container">
                    <div className="center" style={{ paddingTop: '2rem' }}>
                        <p >Preencha os campos abaixo e envie seus dados. Em breve entraremos em contato.</p>
                    </div>

                    <div className="form-widget">
                        <div className="form-result"></div>
                        {!this.state.sent ? <form className="mb-0" onSubmit={this.handleSubmit} >
                            <div className="row">
                                <div className="w-100" style={{ paddingBottom: '3rem' }}>
                                    <div className="col-md-12">
                                        <h5> DADOS PESSOAIS</h5>
                                    </div>
                                </div>
                                <div className="col-md-12 form-group">
                                    <label >Nome <small>*</small></label>
                                    <input onChange={(e) => this.setState({ name: e.target.value })} type="text" id="template-contactform-name" className="sm-form-control required" required />
                                </div>

                                <div className="col-md-4 form-group">
                                    <label >Email <small>*</small></label>
                                    <input onChange={(e) => this.setState({ email: e.target.value })} type="email"  className="required email sm-form-control" required />
                                </div>

                                <div className="col-md-4 form-group">
                                    <label >Telefone</label>
                                    <input onChange={(e) => this.setState({ phone: e.target.value })} type="text" id="template-contactform-phone" className="sm-form-control" required />
                                </div>

                                <div className="col-md-4 form-group">
                                    <label >Celular</label>
                                    <input onChange={(e) => this.setState({ celphone: e.target.value })} type="text" id="template-contactform-phone" className="sm-form-control" required />
                                </div>

                                <div className="w-100"></div>

                                <div className="col-md-4 form-group">
                                    <label >UF <small>*</small></label>
                                    <input onChange={(e) => this.setState({ uf: e.target.value })} type="text" id="template-contactform-subject" className="required sm-form-control" required />
                                </div>

                                <div className="col-md-4 form-group">
                                    <label >Cidade <small>*</small></label>
                                    <input onChange={(e) => this.setState({ city: e.target.value })}type="text" id="template-contactform-subject" className="required sm-form-control" required />
                                </div>

                                <div className="col-md-4 form-group">
                                    <label >Melhor horário para contato <small>*</small></label>
                                    <input onChange={(e) => this.setState({ besttime: e.target.value })} type="text" id="template-contactform-subject" className="required sm-form-control" required />
                                </div>

                                <div className="w-100" style={{ paddingBottom: '2rem', paddingTop: '2rem' }}>
                                    <div className="col-md-12">
                                        <h5> DADOS DA OBRA</h5>
                                    </div>
                                </div>

                                <div className="col-md-4 form-group">
                                    <label>Tipo de obra</label>
                                    <select onChange={(e) => this.setState({ construction: e.target.value })} id="template-contactform-service" className="sm-form-control" required>
                                        <option value="- Selecione -">- Selecione -</option>
                                        <option value="Residencial">Residencial</option>
                                        <option value="Industrial">Industrial</option>
                                    </select>
                                </div>

                                <div className="col-md-4 form-group">
                                    <label>Serviço</label>
                                    <select onChange = { (event) => { this.setService(event.target.value); } } id="template-contactform-service" className="sm-form-control">
                                        <option value="- Selecione -">- Selecione -</option>
                                        <option value="Britador">Britador</option>
                                        {this.state.services.map((item, i) =>
                                            <option value={item.title}>{item.title}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Atividade/Produto</label>
                                    <select onChange={(e) => this.setState({ selectedProduct: e.target.value })} id="template-contactform-service" className="sm-form-control" required disabled={this.checkBrit()}>
                                        <option value="- Selecione -">- Selecione -</option>
                                        {this.getProducts().map((item, i) =>
                                            <option value={item}>{item}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="col-12 form-group">
                                    <label >Descreva mais detalhes da obra que pretende realizar:  <small>*</small></label>
                                    <textarea onChange={(e) => this.setState({ details: e.target.value })} className="required sm-form-control" required></textarea>
                                </div>

                                <div className="col-12 form-group">
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={GOOGLE_SITE_KEY}
                                    />
								</div>

                                <div className="col-12 form-group">
                                    
                                    <button className="btn-saiba-mais-orange" type="submit" value="submit">
                                        ENVIAR
                                    </button>                                    
                                                * Campos com preenchimento obrigatório
                                            </div>
                                </div>

                                {this.state.checkFields ? <div style={{textAlign: 'center'}}><h4 style={{color: "orange"}}>Verifique os dados informados</h4></div> : null}
                                {this.state.err ? <div style={{textAlign: 'center'}}><h4 style={{color: "red"}}>Verifique a sua conexão e tente novamente</h4></div> : null}
                        </form> : <div style={{textAlign: 'center'}}><h3 style={{color: "green"}}>Obrigado pelo seu contato!</h3></div>}
                    </div>
                </section>
                <Contato />
            </>
            </Animate>
        );
    }
}
export default Orcamento;