import Contato from "../../components/contato/Contato";
import Header from "../../components/header/Header";
import React from 'react';
import { API_URL, axiosconfigwithfiles, GOOGLE_SITE_KEY } from '../../core';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import Animate from 'react-smooth';

const recaptchaRef = React.createRef();

class TrabalheConosco extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			name: '',
			phone: '',
			email: '',
            celphone: '',
			xp: '',
            curr : null,
			sent: false,
            err : false
		};
		this.handleSubmit = this.handleSubmit.bind(this);
	}

    onFileChange = event => {
        this.setState({ curr: event.target.files[0] });
      };

	handleSubmit(e) {        
		e.preventDefault();
        const recaptchaValue = recaptchaRef.current.getValue();
        const data = new FormData()
        data.append('curr', this.state.curr);
        data.append('name', this.state.name);
        data.append('phone', this.state.phone);
        data.append('job', this.state.job);
        data.append('email', this.state.email);
        data.append('celphone', this.state.celphone);
        data.append('xp', this.state.xp);		
        data.append('grecaptcha', recaptchaValue);
		axios.post(`${API_URL}/api/job`, data, axiosconfigwithfiles)
			.then(res => {
				if (res.status === 200) {
					this.setState({ sent: true, err: false })
				}
			}).catch(err =>
                this.setState({err: true})
            )
		return false;
	}
    render(){
        return (
            <Animate to="1" from="0" attributeName="opacity">
            <>
                <Header />
                <div className="clearfix " style={{ paddingTop: '3rem' }}>
                    <section id="page-title" className="page-title-center">
                        <div className="container clearfix left">
                            <h2>Trabalhe Conosco</h2>
                            <span>O Grupo Zancanaro está constantemente expandindo seus negócios.  </span>
                            <span style={{fontSize: '1.08rem'}}>Se você está em busca de oportunidade no mercado de trabalho, cadastre seu currículo abaixo.</span>
                        </div>
                    </section>
                </div>
                <section className="container">
                    <div className="center" style={{ paddingTop: '2rem' }}>
                        <p >Preencha o formulário.</p>
                    </div>
                    <div className="form-widget">
                        <div className="form-result"></div>
                        {!this.state.sent ?
                        <form className="mb-0" onSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <label >Nome <small>*</small></label>
                                    <input onChange={(e) => this.setState({ name: e.target.value })} type="text" className="sm-form-control required" required />
                                </div>

                                <div className="col-md-4 form-group">
                                    <label >Email <small>*</small></label>
                                    <input onChange={(e) => this.setState({ email: e.target.value })} type="email" className="required email sm-form-control" required />
                                </div>

                                <div className="col-md-4 form-group">
                                    <label >Telefone</label>
                                    <input onChange={(e) => this.setState({ phone: e.target.value })} type="text" className="sm-form-control" required />
                                </div>

                                <div className="col-md-4 form-group">
                                    <label >Celular  <small>*</small></label>
                                    <input onChange={(e) => this.setState({ celphone: e.target.value })} type="text" className="required sm-form-control" required />
                                </div>

                                <div className="w-100"></div>

                                <div className="col-md-12 form-group">
                                    <label >Vaga pretendida <small>*</small></label>
                                    <input onChange={(e) => this.setState({ job: e.target.value })} type="text" className="required sm-form-control" required />
                                </div>

                                <div className="col-12 form-group">
                                    <label >Descreva brevemente sua experiência profissional e diferenciais:  <small>*</small></label>
                                    <textarea onChange={(e) => this.setState({ xp: e.target.value })} className="required sm-form-control" required></textarea>
                                </div>
                                <div className="col-md-12 form-group">
                                    <label >Currículo (PDF) <small>*</small></label>
                                    <input onChange={this.onFileChange} type="file" className="required sm-form-control" required/>
                                </div>
                                <div className="col-12 form-group">
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={GOOGLE_SITE_KEY}
                                    />
								</div>
                                <div className="col-12 form-group">
                                    <button className="btn-saiba-mais-orange" type="submit" value="submit">
                                        ENVIAR
                                    </button>
                                    * Campos com preenchimento obrigatório
                                </div>
                            </div>
                        </form>:
                        <div style={{textAlign: 'center'}}><h3 style={{color: "green"}}>Obrigado pelo seu contato!</h3></div>
                        }
                        {this.state.err ? <div style={{textAlign: 'center'}}><h4 style={{color: "red"}}>Verifique a sua conexão e tente novamente</h4></div> : null}
                    </div>
                </section>
                <Contato />
            </>
            </Animate>
        );
   }
}
export default TrabalheConosco;