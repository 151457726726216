import ReactDOM from 'react-dom';
import App from './App';
import 'animate.css';
import ReactGA from 'react-ga';

ReactGA.initialize('G-D9CEQM11QT');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

