import { Carousel } from 'react-bootstrap';
import banner from '../../images/BANNER-PRINCIPAL.png';
import React from 'react';
import { API_URL, axiosconfig } from '../../core';
import axios from 'axios';
import Animate from 'react-smooth';
import './Banner.css';

class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            layout: {}
        };
      }    
    componentDidMount() {
        let url = API_URL + '/api/layout?format=json'
        axios.get(url, axiosconfig)
            .then(res => {
            this.setState({ layout : res.data });
        })
    }
    render(){
        return (
            <Animate to="1" from="0" attributeName="opacity">
            <Carousel className="banner">
                <Carousel.Item>
                    {!this.state.layout.banner01 ? 
                        <img
                        className="d-block w-100"
                        src={banner}
                        alt="Zancanaro"
                        />
                    :
                        <img
                        className="d-block w-100"
                        src={`${API_URL}${this.state.layout.banner01}`}
                        alt="Zancanaro"
                        />    
                    }
                    {/*<Carousel.Caption>
                    <h3 className="banTitle">Pavimentação</h3>
                        <p>Resistente, segura e uniforme</p>
                    </Carousel.Caption>
                    */}
                </Carousel.Item>
                <Carousel.Item>
                {!this.state.layout.banner02 ? 
                        <img
                        className="d-block w-100"
                        src={banner}
                        alt="Zancanaro"
                        />
                    :
                        <img
                        className="d-block w-100"
                        src={`${API_URL}${this.state.layout.banner02}`}
                        alt="Zancanaro"
                        />    
                    }
                   {/*<Carousel.Caption>
                    <h3 className="banTitle">Pavimentação</h3>
                        <p>Resistente, segura e uniforme</p>
                    </Carousel.Caption>
                    */}
                </Carousel.Item>
                <Carousel.Item>
                    {!this.state.layout.banner03 ? 
                        <img
                        className="d-block w-100"
                        src={banner}
                        alt="Zancanaro"
                        />
                    :
                        <img
                        className="d-block w-100"
                        src={`${API_URL}${this.state.layout.banner03}`}
                        alt="Zancanaro"
                        />    
                    }
                    {/*<Carousel.Caption>
                    <h3 className="banTitle">Pavimentação</h3>
                        <p>Resistente, segura e uniforme</p>
                    </Carousel.Caption>
                    */}
                </Carousel.Item>
                <Carousel.Item>
                    {!this.state.layout.banner04 ? 
                        <img
                        className="d-block w-100"
                        src={banner}
                        alt="Zancanaro"
                        />
                    :
                        <img
                        className="d-block w-100"
                        src={`${API_URL}${this.state.layout.banner04}`}
                        alt="Zancanaro"
                        />    
                    }
                    {/*<Carousel.Caption>
                    <h3 className="banTitle">Pavimentação</h3>
                        <p>Resistente, segura e uniforme</p>
                    </Carousel.Caption>
                    */}
                </Carousel.Item>
                </Carousel>
                </Animate>
        );
    }

}
export default Banner;