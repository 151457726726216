import imgone from '../../images/middle-banner/britador.png';
import backgroundZmixImg from '../../images/middle-banner/zmix_fundo.png';
import caminhaoZmixImg from '../../images/middle-banner/caminhao_zmix.png';
import arrowbtnbritador from '../../images/arrow_button_britador.png';
import './Bannerorcamento.css';
import { CardOrcamento } from '../cardorcamento/CardOrcamento';

export function Bannerorcamento() {
    return (
        <div className="section" style={{ backgroundColor: 'white', marginBottom: '0', padding: '0' }}>
            <div className="section" style={{ backgroundColor: 'white', marginBottom: '0', padding: '0' }}>
                <CardOrcamento />
            </div>

            <div className="row">
                <div className="col-lg-6 px-0">
                    <img className="w-100 position-relative" src={imgone} alt="background" />
                    <div className="position-absolute">
                        <a href="/materiais/Britador">
                            <button className="btn-saiba-mais-orange" style={{ textAlign: 'left' }}>
                                <img className="arrowbtnpedreira" src={arrowbtnbritador} alt="arrow" data-aos="fade-right"
                                    data-aos-offset="200"
                                    data-aos-delay="50"
                                    data-aos-duration="800" />
                                Saiba mais 
                            </button>
                        </a>
                    </div>
                </div>

                <div className="col-lg-6 px-0">
                    <img className="w-100 position-relative" src={backgroundZmixImg} alt="background" />
                    <div className="position-absolutebtnorc">
                        <a href="/orcamento">
                            <button className="btn-gray" style={{ textAlign: 'left' }}>Faça seu orçamento agora</button>
                        </a>
                    </div>
                    <div className="position-absoluteimg" data-aos="fade-left" data-aos-duration="1500">
                        <div >
                            <img src={caminhaoZmixImg} alt="caminhão zmix" />
                        </div>
                    </div>
                </div>
            </div>
        </div >

    );
}