import ImageGallery from 'react-image-gallery';
import { photos } from '../../photos';
import Header from "../../components/header/Header";
import logo from '../../images/logoSemFundo.png';
import Contato from "../../components/contato/Contato";
import React from 'react';
import { API_URL, axiosconfig } from '../../core';
import axios from 'axios';
import Animate from 'react-smooth';
import "react-image-gallery/styles/css/image-gallery.css";

class Sobre extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
          about: {},
          gallery: []
        };
      }
      componentDidMount() {
          let url = API_URL + `/api/about?format=json`
          axios.get(url, axiosconfig)
              .then(res => {
              this.setState({ about : res.data });
          })
          let url2 = API_URL + `/api/gallery?format=json`
          axios.get(url2, axiosconfig)
              .then(res => {
              this.setState({ gallery : res.data });
          })
      }
      getImages(){
          return this.state.gallery[0] ? this.state.gallery : photos;
      }
      render(){
        return (
            <Animate to="1" from="0" attributeName="opacity">
            <>
                <Header />
                <div className="clearfix " style={{ paddingTop: '4rem' }}>
                    <section id="page-title" className="page-title-center">
    
                        <div className="container clearfix">
                            <img src={logo} alt="logo" className="logo" style={{ maxWidth: '250px' }} />
                            <h2 style={{ paddingTop: '1.5rem' }}>Nossa História</h2>
                            <span>Conheça um pouco mais sobre o Grupo Zancanaro</span>
                        </div>
                    </section>
                </div>
                <section id="content">
                    <div className="content">
                        <div className="container clearfix">
                            <div className="row col-mb-50">
                                <div className="col-md-12 left" style={{whiteSpace: 'pre-wrap', paddingBottom: '5rem', paddingTop: '3rem' }} >
                                    <h5>{this.state.about.title01}</h5>
                                    {this.state.about.text01}
                                </div>
                                <div className="col-md-12 left" style={{whiteSpace: 'pre-wrap'}}>
                                    <h5>{this.state.about.title02}</h5>
                                    {this.state.about.text02}
    
                                </div>
                                <div className="col-md-12 left" style={{whiteSpace: 'pre-wrap'}}>
                                    <h5>{this.state.about.title03}</h5>
                                    {this.state.about.text03}
                                </div>
                                <div className="w-100"></div>
                                <div className="col-md-12 center">
                                    <h5 style={{ paddingBottom: '3rem' }}>Galeria de Obras</h5>
                                    <ImageGallery items={this.getImages()} showThumbnails={true} thumbnailPosition={'left'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Contato />
            </>
            </Animate>
        );
      }
}
export default Sobre;