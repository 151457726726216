export function CardOrcamento() {
    return (
        <>
            <div className="promo promo-dark promo-full p-4" >
                <div className="container clearfix" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                    <div className="row ">
                        <div className="col-12 col-lg">
                            <h3 style={{ fontSize: '30px', fontWeight: 'normal' }}>Garanta agilidade e eficiência para sua obra avançar.</h3>
                            <h3 style={{ color: '#ff6000' }}>Conte com quem é especialista no assunto.</h3>
                        </div>
                        <div className="col-12 col-lg-auto mt-4 mt-lg-0">
                            <a href="/orcamento" className="button button-large btn-orcamento" style={{ fontSize: '1rem' }} >Faça seu orçamento agora</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};