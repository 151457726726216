import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './styles/global.css';
import './styles/bootstrap.css';
import './styles/animate.css';
import './styles/font-icons.css';
import { Home } from './pages/home/Home';
import Blog from './pages/blog/Blog';
import BlogDetail from './pages/blogdetail/BlogDetail';
import Orcamento from './pages/orcamento/Orcamento';
import TrabalheConosco from './pages/trabalheconosco/TrabalheConosco';
import ServiceDetail from './pages/servicedetail/ServiceDetails';
import Materiais from './pages/materiais/Materiais';
import Sobre from './pages/sobre/Sobre';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={Materiais} path={'/materiais/:slug'} />
        <Route component={Blog} path={'/blog'} />
        <Route component={BlogDetail} path={'/pub/:slug'} />
        <Route component={Sobre} path={'/sobre'} />
        <Route component={Orcamento} path={'/orcamento'} />
        <Route component={TrabalheConosco} path={'/trabalheconosco'} />
        <Route component={BlogDetail} path={'/blogdetail'} />
        <Route component={ServiceDetail} path={'/servico/:slug'} />
        <Route component={Home} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
