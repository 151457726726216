import "./Header.css";
import logo from '../../images/logoSemFundo.png';
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import Hamburger from 'hamburger-react';
import React from 'react';
import { API_URL, axiosconfig } from '../../core';
import axios from 'axios';
import Animate from 'react-smooth';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            services: [],/*materials: [],*/
        };
    }

    componentDidMount() {
        let url = API_URL + '/api/services?format=json';
        axios.get(url, axiosconfig)
            .then(res => {
                this.setState({ services: res.data });
            })
        /* let url2 = API_URL + '/api/materials?format=json';
        axios.get(url2, axiosconfig)
            .then(res => {
            this.setState({ materials : res.data });
        })*/
    }
    render() {
        return (
            <Animate to="1" from="0" attributeName="opacity">
            <Navbar collapseOnSelect expand="sm" bg="light" variant="light" fixed='top' className="header shadow-sm mb-5 bg-body rounded">
                <Container>
                    <a href="/" className={'brandMob'}>
                        <img src={logo} style={{ maxWidth: '80px' }} className="logo" alt={'Zancanaro'} />
                    </a>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ border: 0 }}><Hamburger direction="right" /></Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto"></Nav>
                        <Nav className={"cNav"} style={{ textAlign: 'center', display: 'flex' }}>
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/materiais/zmix">Zmix</Nav.Link>
                            <Nav.Link href="/servico/terraplenagem">Ezcava</Nav.Link>
                            {/*<NavDropdown title="Zmix" id="collasible-nav-dropdown">
                        {this.state.materials.map((item, i) => 
                            <NavDropdown.Item key={i} href={`/materiais/${item.title}`}>{item.title}</NavDropdown.Item>
                        )}</NavDropdown> 
                    */}
                            <NavDropdown title="Serviços" id="collasible-nav-dropdown">
                                <NavDropdown.Item key={99} href={`/materiais/britador`}>Britador</NavDropdown.Item>
                                <NavDropdown.Item key={99} href={`/materiais/zmix`}>Concreto e Argamassa</NavDropdown.Item>
                                {this.state.services.map((item, i) =>
                                    <NavDropdown.Item key={i} href={`/servico/${item.slug}`}>{item.title}</NavDropdown.Item>
                                )}
                            </NavDropdown>
                            <Nav.Link href="/sobre">Sobre Nós</Nav.Link>
                            <Nav.Link href="/blog">Blog</Nav.Link>
                            <NavDropdown title="Contato" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/#contato">Fale conosco</NavDropdown.Item>
                                <NavDropdown.Item href="/orcamento">Orçamento</NavDropdown.Item>
                                <NavDropdown.Item href="/trabalheconosco">Trabalhe conosco</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            </Animate>
        );
    }
};

export default Header;
