import logo from '../../images/logoSemFundo.png';
import React from 'react';
import { API_URL, axiosconfig, GOOGLE_SITE_KEY } from '../../core';
import axios from 'axios';
import './Contato.css';
import ReCAPTCHA from "react-google-recaptcha";
import Animate from 'react-smooth';

const recaptchaRef = React.createRef();

class Contato extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			social: {},
			name: '',
			phone: '',
			email: '',
			dept: 'Argamassa',
			message: '',
			business: '',
			sent: false,
			err: false,
			captcha: '',
			sending: false
		};
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(e) {
		e.preventDefault();
		const recaptchaValue = recaptchaRef.current.getValue();
		const data = new FormData()
		data.append('name', this.state.name);
		data.append('phone', this.state.phone);
		data.append('dept', this.state.dept);
		data.append('email', this.state.email);
		data.append('business', this.state.business);
		data.append('message', this.state.message);
		data.append('grecaptcha', recaptchaValue);
		axios.post(`${API_URL}/api/contact`, data, axiosconfig)
			.then(res => {
				if (res.status === 200) {
					this.setState({ sent: true, err: false })
				}
			}).catch(err =>
				this.setState({ err: true })
			)
		return false;
	}
	componentDidMount() {
		let url = API_URL + '/api/social?format=json'
		axios.get(url, axiosconfig)
			.then(res => {
				this.setState({ social: res.data });
			})
	}
	render() {
		return (
			<Animate to="1" from="0" attributeName="opacity">
			<div className="container" id="contato" style={{ paddingTop: '8rem' }}>
				<div className="row">
					<div className="col-lg-6" style={{ textAlign: 'center' }}>
						<img src={logo} alt="" style={{ width: '30%' }} className="logo" />
						<p style={{ paddingTop: '0.5rem' }}>Entre em contato</p>
						<h4 style={{ color: '#ff6000', fontWeight: 'bold' }}>(46) 3224 1715</h4>						
						<address style={{ paddingTop: '2rem', paddingBottom: '1rem' }}>
							<strong>Localização:</strong><br />
									Rod BR 158 KM 518 n° 2800 Núcleo Bom Retiro, Bairro São Francisco<br />
									CEP 85.501-970 - Pato Branco - PR<br />
						</address>
						<div style={{ textAlign: 'center' }}>
							<p>Como chegar</p>
						</div>
						<div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
							<iframe title="Intentionally blank" aria-hidden="true" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7158.543945028995!2d-52.680721056116454!3d-26.220351079906752!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94e553207a1bfee1%3A0x183a69cad819bd01!2sGrupo%20Zancanaro!5e0!3m2!1spt-BR!2sbr!4v1615196428988!5m2!1spt-BR!2sbr" width="300" height="300" style={{ border: '0' }} loading="lazy"></iframe>
						</div>
					</div>
					<div className="col-lg-6">
						<h3 style={{ color: '#ff6000', fontWeight: 'bold', fontSize: '35px', marginBottom: '40px' }}>Entre em contato conosco</h3>
						<div className="form-widget">
							{!this.state.sent ?
								<form className="mb-0" onSubmit={this.handleSubmit}>
									<div className="row">
										<div className="col-md-6 form-group">
											<label >Nome <small>*</small></label>
											<input type="text" onChange={(e) => this.setState({ name: e.target.value })} name="name" className="sm-form-control" required />
										</div>

										<div className="col-md-6 form-group">
											<label >Telefone</label>
											<input type="text" onChange={(e) => this.setState({ phone: e.target.value })} name="phone" className="sm-form-control" required />
										</div>

										<div className="col-md-12 form-group">
											<label >Email <small>*</small></label>
											<input type="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} name="email" className="sm-form-control" required />
										</div>

										<div className="w-100"></div>

										<div className="col-md-6 form-group">
											<label >Empresa <small>*</small></label>
											<input type="text" onChange={(e) => this.setState({ business: e.target.value })} name="business" className="required sm-form-control" required />
										</div>

										<div className="col-md-6 form-group">
											<label>Setor</label>
											<select name="dept" className="sm-form-control" onChange={(e) => this.setState({ dept: e.target.value })}>
												<option value="Argamassa">Argamassa</option>
												<option value="Compras">Compras</option>
												<option value="Comunicação e Marketing">Comunicação e Marketing</option>
												<option value="Concreto">Concreto</option>
												<option value="Horas Máquinas">Horas Máquinas</option>
												<option value="Pavimentação Asfáltica">Pavimentação Asfáltica</option>
												<option value="Pedras">Pedras</option>
												<option value="Terraplenagens">Terraplenagens</option>
											</select>
										</div>

										<div className="w-100"></div>

										<div className="col-12 form-group">
											<label >Mensagem <small>*</small></label>
											<textarea onChange={(e) => this.setState({ message: e.target.value })} name="message" className="required sm-form-control" required></textarea>
										</div>
										<div className="col-12 form-group">
											<ReCAPTCHA
												ref={recaptchaRef}
												sitekey={GOOGLE_SITE_KEY}
											/>
										</div>

										<div className="col-12 form-group">
											<button className="btn-saiba-mais-orange" type="submit" value="submit">
												ENVIAR
											</button>
												* Campos com preenchimento obrigatório
											</div>
									</div>
								</form> :
								<div style={{ textAlign: 'center' }}><h3 style={{ color: "green" }}>Obrigado pelo seu contato!</h3></div>
							}
							{this.state.err ? <div style={{ textAlign: 'center' }}><h4 style={{ color: "red" }}>Verifique a sua conexão e tente novamente</h4></div> : null}
						</div>
						<p style={{ marginTop: '30px', fontWeight: 'bold' }}>Siga-nos nas redes sociais</p>
						<div>
						{this.state.social.facebook ?
							<a href={`${this.state.social.facebook}`} target="_blank" rel="noreferrer" className="social-icon si-small si-dark si-facebook">
								<i className="icon-facebook"></i>
								<i className="icon-facebook"></i>
							</a>
						:
						null}
						{this.state.social.instagram ?
							<a href={`${this.state.social.instagram}`} target="_blank" rel="noreferrer" className="social-icon si-small si-dark si-instagram">
								<i className="icon-instagram"></i>
								<i className="icon-instagram"></i>
							</a>
						:
						null}
						{this.state.social.youtube ?
							<a href={`${this.state.social.youtube}`} target="_blank" rel="noreferrer" className="social-icon si-small si-dark si-youtube">
								<i className="icon-youtube"></i>
								<i className="icon-youtube"></i>
							</a>
						:
						null
						}
						</div>
					</div>
				</div>
				<div className="center" style={{ paddingTop: '4rem', paddingBottom: '1rem' }}>
					<p>Copyright © 2021, Todos os direitos reservados Grupo Zancanaro e Camaleon360</p>
				</div>
				<div className="logotech">
					<span>Desenvolvido por
						<a href="https://camaleonmkt.com.br/" target="blank" style={{ margin: '0.2rem' }}>
							Camaleon MKT
						</a>
						e
						<a href="https://www.techraptors.com.br/" target="blank" style={{ margin: '0.2rem' }}>
							TECHRAPTORS
						</a>
					</span>
				</div>
			</div>
			</Animate>
		);
	}
}
export default Contato;
