import Contato from "../../components/contato/Contato";
import Header from "../../components/header/Header";
import React from 'react';
import { API_URL, axiosconfig } from '../../core';
import axios from 'axios';
import Animate from 'react-smooth';

class BlogDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
          pub: {}
        };
      }
      componentDidMount() {
          let url = API_URL + `/api/pub?slug=${this.props.match.params.slug}&format=json`
          axios.get(url, axiosconfig)
              .then(res => {
              this.setState({ pub : res.data });
          })
      }
      formatterDate(dt){
        return dt ? Intl.DateTimeFormat('pt-BR').format(new Date(dt)) : '';
      }
      render(){
        return (
            <Animate to="1" from="0" attributeName="opacity">
            <>
                <Header />
                <section id="content" >
                    <div className="content-wrap" style={{ paddingTop: '5rem', marginBottom: '0', paddingBottom: '0px' }}>
                        <div className="container clearfix">
                            <div className="row gutter-40 col-mb-80">
                                <div className="postcontent col-lg-12">
                                    <div className="single-post mb-0">
                                        <div className="entry clearfix">
                                            <div className="entry-title">
                                                <h2>{this.state.pub.title}</h2>
                                            </div>
                                            <div className="entry-meta">
                                                <ul>
                                                    <li><i className="icon-calendar3"></i> {this.formatterDate(this.state.pub.pub)}</li>
                                                </ul>
                                            </div>
                                            <div style={{textAlign: 'center', justifyContent: 'center', display: 'flex'}}><img src={`${API_URL}${this.state.pub.image}`} alt={this.state.pub.title} style={{maxWidth: '450px'}} /></div>
                                            <br/>
                                            <div className="entry-content mt-0" style={{whiteSpace: 'pre-wrap'}}>
                                                {this.state.pub.content}
                                                <div className="clear"></div>
                                                <br/>                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Contato />
            </>
            </Animate>
        );
      }
   
}
export default BlogDetail;