import Header from "../../components/header/Header";
import './Materiais.css';
import Contato from "../../components/contato/Contato";
import { CardOrcamento } from "../../components/cardorcamento/CardOrcamento";
import React from 'react';
import { API_URL, axiosconfig } from '../../core';
import axios from 'axios';
import Animate from 'react-smooth';

class Materiais extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mats: [],
            material: {}
        };
    }
    componentDidMount() {
        let url = API_URL + `/api/materialItems?material=${this.props.match.params.slug}&format=json`
        axios.get(url, axiosconfig)
            .then(res => {
                this.setState({ mats: res.data });
            })
        let url2 = API_URL + `/api/material?material=${this.props.match.params.slug}&format=json`
        axios.get(url2, axiosconfig)
            .then(res => {
                this.setState({ material: res.data });
            })
    }
    render() {
        return (
            <Animate to="1" from="0" attributeName="opacity">
            <div className="App">
                <div className="stretched">
                    <div className="">
                        <Header />
                        <div className=" mgtop">
                            {this.state.material.banner ?
                                <img src={`${API_URL}${this.state.material.banner}`} alt="Zancanaro" style={{ width: '100%' }} />
                                : null}
                        </div>
                        <div className="container left">
                            <a href="/orcamento">
                                <button className="btn-orcamento" style={{ marginTop: '0' }}>
                                    Faça seu orçamento agora!
                                </button>
                            </a>
                        </div>
                        <div className="container" style={{ marginTop: '0.8rem' }}>

                            <div className="gridcontainer">

                                {this.state.mats.map((item, i) =>
                                    <>
                                        {item.supertitle !== '' ? 
                                            <div className="superTitle">
                                                <h4 className="center bold titulo">{item.supertitle}</h4>
                                            </div>: null
                                        }
                                        <div className="grid-item">
                                            <img src={`${API_URL}${item.image}`} alt="material zancanaro" />
                                        </div>
                                        <div className="grid-item">
                                            <h5 className="color-orange bold">{item.title}</h5>
                                            <span className="descricao" style={{ whiteSpace: 'pre-wrap' }}>
                                                {item.content}
                                            </span>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="section" style={{ backgroundColor: 'white', marginBottom: '0', padding: '0' }}>
                            <CardOrcamento />
                        </div>
                        <Contato />
                    </div>
                </div>
            </div >
            </Animate>
        );
    }

}
export default Materiais;